export default [
  {
    id: "ARICA",
    name: "Arica y Parinacota",
    romanNumber: "XV",
    number: 15,
    code: "15",
    communes: [
      { id: "ARICA", name: "Arica", code: "15101" },
      { id: "CAMARONES", name: "Camarones", code: "15102" },
      { id: "GENERAL LAGOS", name: "General Lagos", code: "15202" },
      { id: "PUTRE", name: "Putre", code: "15201" },
    ],
  },
  {
    id: "TARAPACA",
    name: "Tarapacá",
    romanNumber: "I",
    number: 1,
    code: "01",
    communes: [
      { id: "ALTO HOSPICIO", name: "Alto Hospicio", code:"1107" },
      { id: "CAMIÑA", name: "Camiña", code:"1202" },
      { id: "COLCHANE", name: "Colchane", code:"1203" },
      { id: "HUARA", name: "Huara", code:"1204" },
      { id: "IQUIQUE", name: "Iquique", code:"1101" },
      { id: "PICA", name: "Pica", code:"1205" },
      { id: "POZO ALMONTE", name: "Pozo Almonte", code:"1201" },
    ],
  },
  {
    id: "ANTOFAGASTA",
    name: "Antofagasta",
    romanNumber: "II",
    number: 2,
    code: "02",
    communes: [
      { id: "ANTOFAGASTA", name: "Antofagasta", code: "2101" },
      { id: "CALAMA", name: "Calama", code: "2201" },
      { id: "MARIA ELENA", name: "María Elena", code: "2302" },
      { id: "MEJILLONES", name: "Mejillones", code: "2102" },
      { id: "OLLAGUE", name: "Ollagüe", code: "2202" },
      { id: "SAN PEDRO DE ATACAMA", name: "San Pedro de Atacama", code: "2203" },
      { id: "SIERRA GORDA", name: "Sierra Gorda", code: "2103" },
      { id: "TALTAL", name: "Taltal", code: "2104" },
      { id: "TOCOPILLA", name: "Tocopilla", code: "2301" },
    ],
  },
  {
    id: "ATACAMA",
    name: "Atacama",
    romanNumber: "III",
    number: 3,
    code: "03",
    communes: [
      { id: "ALTO DEL CARMEN", name: "Alto del Carmen", code: "3302" },
      { id: "CALDERA", name: "Caldera", code: "3102" },
      { id: "CHANARAL", name: "Chañaral", code: "3201" },
      { id: "COPIAPO", name: "Copiapó", code: "3101" },
      { id: "DIEGO DE ALMAGRO", name: "Diego de Almagro", code: "3202" },
      { id: "FREIRINA", name: "Freirina", code: "3303" },
      { id: "HUASCO", name: "Huasco", code: "3304" },
      { id: "TIERRA AMARILLA", name: "Tierra Amarilla", code: "3103" },
      { id: "VALLENAR", name: "Vallenar", code: "3301" },
    ],
  },
  {
    id: "COQUIMBO",
    name: "Coquimbo",
    romanNumber: "IV",
    number: 4,
    code: "04",
    communes: [
      { id: "ANDACOLLO", name: "Andacollo", code: "4103" },
      { id: "CANELA", name: "Canela", code: "4202" },
      { id: "COMBARBALA", name: "Combarbalá", code: "4302" },
      { id: "COQUIMBO", name: "Coquimbo", code: "4102" },
      { id: "ILLAPEL", name: "Illapel", code: "4201" },
      { id: "LA HIGUERA", name: "La Higuera", code: "4104" },
      { id: "LA SERENA", name: "La Serena", code: "4101" },
      { id: "LOS VILOS", name: "Los Vilos", code: "4203" },
      { id: "MONTE PATRIA", name: "Monte Patria", code: "4303" },
      { id: "OVALLE", name: "Ovalle", code: "4301" },
      { id: "PAIGUANO", name: "Paiguano", code: "4105" },
      { id: "PUNITAQUI", name: "Punitaqui", code: "4304" },
      { id: "RIO HURTADO", name: "Río Hurtado", code: "4305" },
      { id: "SALAMANCA", name: "Salamanca", code: "4204" },
      { id: "VICUNA", name: "Vicuña", code: "4106" },
    ],
  },
  {
    id: "VALPARAISO",
    name: "Valparaíso",
    romanNumber: "V",
    number: 5,
    code: "05",
    communes: [
      { id: "ALGARROBO", name: "Algarrobo", code: "5602" },
      { id: "CABILDO", name: "Cabildo", code: "5402" },
      { id: "CALERA", name: "Calera", code: "5502" },
      { id: "CALLE LARGA", name: "Calle Larga", code: "5302" },
      { id: "CARTAGENA", name: "Cartagena", code: "5603" },
      { id: "CASABLANCA", name: "Casablanca", code: "5102" },
      { id: "CATEMU", name: "Catemu", code: "5702" },
      { id: "CON CON", name: "Concón", code: "5103" },
      { id: "EL QUISCO", name: "El Quisco", code: "5604" },
      { id: "EL TABO", name: "El Tabo", code: "5605" },
      { id: "HIJUELAS", name: "Hijuelas", code: "5503" },
      { id: "ISLA DE PASCUA", name: "Isla de Pascua", code: "5201" },
      { id: "JUAN FERNANDEZ", name: "Juan Fernández", code: "5104" },
      { id: "LA CRUZ", name: "La Cruz", code: "5504" },
      { id: "LA LIGUA", name: "La Ligua", code: "5401" },
      { id: "LIMACHE", name: "Limache", code: "5802" },
      { id: "LLAILLAY", name: "Llaillay", code: "5703" },
      { id: "LOS ANDES", name: "Los Andes", code: "5301" },
      { id: "NOGALES", name: "Nogales", code: "5506" },
      { id: "OLMUE", name: "Olmué", code: "5803" },
      { id: "PANQUEHUE", name: "Panquehue", code: "5704" },
      { id: "PAPUDO", name: "Papudo", code: "5403" },
      { id: "PETORCA", name: "Petorca", code: "5404" },
      { id: "PUCHUNCAVI", name: "Puchuncaví", code: "5105" },
      { id: "PUTAENDO", name: "Putaendo", code: "5705" },
      { id: "QUILLOTA", name: "Quillota", code: "5501" },
      { id: "QUILPUE", name: "Quilpué", code: "5801" },
      { id: "QUINTERO", name: "Quintero", code: "5107" },
      { id: "RINCONADA", name: "Rinconada", code: "5303" },
      { id: "SAN ANTONIO", name: "San Antonio", code: "5601" },
      { id: "SAN ESTEBAN", name: "San Esteban", code: "5304" },
      { id: "SAN FELIPE", name: "San Felipe", code: "5701" },
      { id: "SANTA MARIA", name: "Santa María", code: "5706" },
      { id: "SANTO DOMINGO", name: "Santo Domingo", code: "5606" },
      { id: "VALPARAISO", name: "Valparaíso", code: "5101" },
      { id: "VILLA ALEMANA", name: "Villa Alemana", code: "5804" },
      { id: "VINA DEL MAR", name: "Viña del Mar", code: "5109" },
      { id: "ZAPALLAR", name: "Zapallar", code: "5405" },
    ],
  },
  {
    id: "METROPOLITANA",
    name: "Metropolitana de Santiago",
    romanNumber: "XIII",
    number: 13,
    code: "13",
    communes: [
      { id: "ALHUE", name: "Alhué", code: "13502" },
      { id: "BUIN", name: "Buin", code: "13402" },
      { id: "CALERA DE TANGO", name: "Calera de Tango", code: "13403" },
      { id: "CERRILLOS", name: "Cerrillos", code: "13102" },
      { id: "CERRO NAVIA", name: "Cerro Navia", code: "13103" },
      { id: "COLINA", name: "Colina", code: "13301" },
      { id: "CONCHALI", name: "Conchalí", code: "13104" },
      { id: "CURACAVI", name: "Curacaví", code: "13503" },
      { id: "EL BOSQUE", name: "El Bosque", code: "13105" },
      { id: "EL MONTE", name: "El Monte", code: "13602" },
      { id: "ESTACION CENTRAL", name: "Estación Central", code: "13106" },
      { id: "HUECHURABA", name: "Huechuraba", code: "13107" },
      { id: "INDEPENDENCIA", name: "Independencia", code: "13108" },
      { id: "ISLA DE MAIPO", name: "Isla de Maipo", code: "13603" },
      { id: "LA CISTERNA", name: "La Cisterna", code: "13109" },
      { id: "LA FLORIDA", name: "La Florida", code: "13110" },
      { id: "LA GRANJA", name: "La Granja", code: "13111" },
      { id: "LA PINTANA", name: "La Pintana", code: "13112" },
      { id: "LA REINA", name: "La Reina", code: "13113" },
      { id: "LAMPA", name: "Lampa", code: "13302" },
      { id: "LAS CONDES", name: "Las Condes", code: "13114" },
      { id: "LO BARNECHEA", name: "Lo Barnechea", code: "13115" },
      { id: "LO ESPEJO", name: "Lo Espejo", code: "13116" },
      { id: "LO PRADO", name: "Lo Prado", code: "13117" },
      { id: "MACUL", name: "Macul", code: "13118" },
      { id: "MAIPU", name: "Maipú", code: "13119" },
      { id: "MARIA PINTO", name: "María Pinto", code: "13504" },
      { id: "MELIPILLA", name: "Melipilla", code: "13501" },
      { id: "ÑUÑOA", name: "Ñuñoa", code: "13120" },
      { id: "PADRE HURTADO", name: "Padre Hurtado", code: "13604" },
      { id: "PAINE", name: "Paine", code: "13404" },
      { id: "PEDRO AGUIRRE CERDA", name: "Pedro Aguirre Cerda", code: "13121" },
      { id: "PEÑAFLOR", name: "Peñaflor", code: "13605" },
      { id: "PEÑALOLEN", name: "Peñalolén", code: "13122" },
      { id: "PIRQUE", name: "Pirque", code: "13202" },
      { id: "PROVIDENCIA", name: "Providencia", code: "13123" },
      { id: "PUDAHUEL", name: "Pudahuel", code: "13124" },
      { id: "PUENTE ALTO", name: "Puente Alto", code: "13201" },
      { id: "QUILICURA", name: "Quilicura", code: "13125" },
      { id: "QUINTA NORMAL", name: "Quinta Normal", code: "13126" },
      { id: "RECOLETA", name: "Recoleta", code: "13127" },
      { id: "RENCA", name: "Renca", code: "13128" },
      { id: "SAN BERNARDO", name: "San Bernardo", code: "13401" },
      { id: "SAN JOAQUIN", name: "San Joaquín", code: "13129" },
      { id: "SAN JOSE DE MAIPO", name: "San José de Maipo", code: "13203" },
      { id: "SAN MIGUEL", name: "San Miguel", code: "13130" },
      { id: "SAN PEDRO", name: "San Pedro", code: "13505" },
      { id: "SAN RAMON", name: "San Ramón", code: "13131" },
      { id: "SANTIAGO", name: "Santiago", code: "13101" },
      { id: "TALAGANTE", name: "Talagante", code: "13601" },
      { id: "TILTIL", name: "Tiltil", code: "13303" },
      { id: "VITACURA", name: "Vitacura", code: "13132" },
    ],
  },
  {
    id: "O'HIGGINS",
    name: "Libertador Gral. Bernardo O’Higgins",
    romanNumber: "VI",
    number: 6,
    code: "06",
    communes: [
      { id: "CHIMBARONGO", name: "Chimbarongo", code: "6303" },
      { id: "CHEPICA", name: "Chépica", code: "6302" },
      { id: "CODEGUA", name: "Codegua", code: "6102" },
      { id: "COINCO", name: "Coinco", code: "6103" },
      { id: "COLTAUCO", name: "Coltauco", code: "6104" },
      { id: "DONIHUE", name: "Doñihue", code: "6105" },
      { id: "GRANEROS", name: "Graneros", code: "6106" },
      { id: "LA ESTRELLA", name: "La Estrella", code: "6202" },
      { id: "LAS CABRAS", name: "Las Cabras", code: "6107" },
      { id: "LITUECHE", name: "Litueche", code: "6203" },
      { id: "LOLOL", name: "Lolol", code: "6304" },
      { id: "MACHALI", name: "Machalí", code: "6108" },
      { id: "MALLOA", name: "Malloa", code: "6109" },
      { id: "MARCHIHUE", name: "Marchihue", code: "6204" },
      { id: "NANCAGUA", name: "Nancagua", code: "6305" },
      { id: "NAVIDAD", name: "Navidad", code: "6205" },
      { id: "OLIVAR", name: "Olivar", code: "6111" },
      { id: "PALMILLA", name: "Palmilla", code: "6306" },
      { id: "PAREDONES", name: "Paredones", code: "6206" },
      { id: "PERALILLO", name: "Peralillo", code: "6307" },
      { id: "PEUMO", name: "Peumo", code: "6112" },
      { id: "PICHIDEGUA", name: "Pichidegua", code: "6113" },
      { id: "PICHILEMU", name: "Pichilemu", code: "6201" },
      { id: "PLACILLA", name: "Placilla", code: "6308" },
      { id: "PUMANQUE", name: "Pumanque", code: "6309" },
      { id: "QUINTA DE TILCOCO", name: "Quinta de Tilcoco", code: "6114" },
      { id: "RANCAGUA", name: "Rancagua", code: "6101" },
      { id: "RENGO", name: "Rengo", code: "6115" },
      { id: "REQUINOA", name: "Requínoa", code: "6116" },
      { id: "SAN FERNANDO", name: "San Fernando", code: "6301" },
      { id: "MOSTAZAL", name: "San Francisco de Mostazal", code: "6110" },
      { id: "SAN VICENTE", name: "San Vicente de Tagua Tagua", code: "6117" },
      { id: "SANTA CRUZ", name: "Santa Cruz", code: "6310" },
    ],
  },
  {
    id: "MAULE",
    name: "Maule",
    romanNumber: "VII",
    number: 7,
    code: "07",
    communes: [
      { id: "CAUQUENES", name: "Cauquenes", code: "7201" },
      { id: "CHANCO", name: "Chanco", code: "7202" },
      { id: "COLBUN", name: "Colbún", code: "7402" },
      { id: "CONSTITUCION", name: "Constitución", code: "7102" },
      { id: "CUREPTO", name: "Curepto", code: "7103" },
      { id: "CURICO", name: "Curicó", code: "7301" },
      { id: "EMPEDRADO", name: "Empedrado", code: "7104" },
      { id: "HUALANE", name: "Hualañé", code: "7302" },
      { id: "LICANTEN", name: "Licantén", code: "7303" },
      { id: "LINARES", name: "Linares", code: "7401" },
      { id: "LOGANVI", name: "Longaví", code: "7403" },
      { id: "MAULE", name: "Maule", code: "7105" },
      { id: "MOLINA", name: "Molina", code: "7304" },
      { id: "PARRAL", name: "Parral", code: "7404" },
      { id: "PELARCO", name: "Pelarco", code: "7106" },
      { id: "PELLUHUE", name: "Pelluhue", code: "7203" },
      { id: "PENCAHUE", name: "Pencahue", code: "7107" },
      { id: "RAUCO", name: "Rauco", code: "7305" },
      { id: "RETIRO", name: "Retiro", code: "7405" },
      { id: "ROMERAL", name: "Romeral", code: "7306" },
      { id: "RIO CLARO", name: "Río Claro", code: "7108" },
      { id: "SAGRADA FAMILIA", name: "Sagrada Familia", code: "7307" },
      { id: "SAN CLEMENTE", name: "San Clemente", code: "7109" },
      { id: "SAN JAVIER", name: "San Javier de Loncomilla", code: "7406" },
      { id: "SAN RAFAEL", name: "San Rafael", code: "7110" },
      { id: "TALCA", name: "Talca", code: "7101" },
      { id: "TENO", name: "Teno", code: "7308" },
      { id: "VICHUQUEN", name: "Vichuquén", code: "7309" },
      { id: "VILLA ALEGRE", name: "Villa Alegre", code: "7407" },
      { id: "YERBAS BUENAS", name: "Yerbas Buenas", code: "7408" },
    ],
  },
  {
    id: "ÑUBLE",
    name: "Ñuble",
    romanNumber: "XVI",
    number: 16,
    code: "16",
    communes: [
      { id: "BULNES", name: "Bulnes", code: "16102" },
      { id: "CHILLAN VIEJO", name: "Chillán Viejo", code: "16103" },
      { id: "CHILLAN", name: "Chillán", code: "16101" },
      { id: "COBQUECURA", name: "Cobquecura", code: "16202" },
      { id: "COELEMU", name: "Coelemu", code: "16203" },
      { id: "COIHUECO", name: "Coihueco", code: "16302" },
      { id: "EL CARMEN", name: "El Carmen", code: "16104" },
      { id: "NINHUE", name: "Ninhue", code: "16204" },
      { id: "ÑIQUEN", name: "Ñiquén", code: "16303" },
      { id: "PEMUCO", name: "Pemuco", code: "16105" },
      { id: "PINTO", name: "Pinto", code: "16106" },
      { id: "PORTEZUELO", name: "Portezuelo", code: "16205" },
      { id: "QUILLON", name: "Quillón", code: "16107" },
      { id: "QUIRIHUE", name: "Quirihue", code: "16201" },
      { id: "RANQUIL", name: "Ránquil", code: "16206" },
      { id: "SAN CARLOS", name: "San Carlos", code: "16301" },
      { id: "SAN FABIAN", name: "San Fabián", code: "16304" },
      { id: "SAN IGNACIO", name: "San Ignacio", code: "16108" },
      { id: "SAN NICOLAS", name: "San Nicolás", code: "16305" },
      { id: "TREGUACO", name: "Treguaco", code: "16207" },
      { id: "YUNGAY", name: "Yungay", code: "16109" },
    ],
  },
  {
    id: "BIO-BIO",
    name: "Biobío",
    romanNumber: "VIII",
    number: 8,
    code: "08",
    communes: [
      { id: "ALTO BIOBIO", name: "Alto Biobío", code: "8314" },
      { id: "ANTUCO", name: "Antuco", code: "8302" },
      { id: "ARAUCO", name: "Arauco", code: "8202" },
      { id: "CABRERO", name: "Cabrero", code: "8303" },
      { id: "CAÑETE", name: "Cañete", code: "8203" },
      { id: "CHIGUAYANTE", name: "Chiguayante", code: "8103" },
      { id: "CONCEPCION", name: "Concepción", code: "8101" },
      { id: "CONTULMO", name: "Contulmo", code: "8204" },
      { id: "CORONEL", name: "Coronel", code: "8102" },
      { id: "CURANILAHUE", name: "Curanilahue", code: "8205" },
      { id: "FLORIDA", name: "Florida", code: "8104" },
      { id: "HUALPEN", name: "Hualpén", code: "8112" },
      { id: "HUALQUI", name: "Hualqui", code: "8105" },
      { id: "LAJA", name: "Laja", code: "8304" },
      { id: "LEBU", name: "Lebu", code: "8201" },
      { id: "LOS ALAMOS", name: "Los Álamos", code: "8206" },
      { id: "LOS ANGELES", name: "Los Ángeles", code: "8301" },
      { id: "LOTA", name: "Lota", code: "8106" },
      { id: "MULCHEN", name: "Mulchén", code: "8305" },
      { id: "NACIMIENTO", name: "Nacimiento", code: "8306" },
      { id: "NEGRETE", name: "Negrete", code: "8307" },
      { id: "PENCO", name: "Penco", code: "8107" },
      { id: "QUILACO", name: "Quilaco", code: "8308" },
      { id: "QUILLECO", name: "Quilleco", code: "8309" },
      { id: "SAN PEDRO DE LA PAZ", name: "San Pedro de la Paz", code: "8108" },
      { id: "SAN ROSENDO", name: "San Rosendo", code: "8310" },
      { id: "SANTA BARBARA", name: "Santa Bárbara", code: "8311" },
      { id: "SANTA JUANA", name: "Santa Juana", code: "8109" },
      { id: "TALCAHUANO", name: "Talcahuano", code: "8110" },
      { id: "TIRUA", name: "Tirúa", code: "8207" },
      { id: "TOME", name: "Tomé", code: "8111" },
      { id: "TUCAPEL", name: "Tucapel", code: "8312" },
      { id: "YUMBEL", name: "Yumbel", code: "8313" },
    ],
  },
  {
    id: "ARAUCANIA",
    name: "Araucanía",
    romanNumber: "IX",
    number: 9,
    code: "09",
    communes: [
      { id: "ANGOL", name: "Angol", code: "9201" },
      { id: "CARAHUE", name: "Carahue", code: "9102" },
      { id: "CHOLCHOL", name: "Cholchol", code: "9121" },
      { id: "COLLIPULLI", name: "Collipulli", code: "9202" },
      { id: "CUNCO", name: "Cunco", code: "9103" },
      { id: "CURACAUTIN", name: "Curacautín", code: "9203" },
      { id: "CURARREHUE", name: "Curarrehue", code: "9104" },
      { id: "ERCILLA", name: "Ercilla", code: "9204" },
      { id: "FREIRE", name: "Freire", code: "9105" },
      { id: "GALVARINNO", name: "Galvarino", code: "9106" },
      { id: "GORBEA", name: "Gorbea", code: "9107" },
      { id: "LAUTARO", name: "Lautaro", code: "9108" },
      { id: "LONCOCHE", name: "Loncoche", code: "9109" },
      { id: "LONQUIMAY", name: "Lonquimay", code: "9205" },
      { id: "LOS SAUCES", name: "Los Sauces", code: "9206" },
      { id: "LUMACO", name: "Lumaco", code: "9207" },
      { id: "MELIPEUCO", name: "Melipeuco", code: "9110" },
      { id: "NUEVA IMPERIAL", name: "Nueva Imperial", code: "9111" },
      { id: "PADRES LAS CASAS", name: "Padre las Casas", code: "9112" },
      { id: "PERQUENCO", name: "Perquenco", code: "9113" },
      { id: "PITRUFQUEN", name: "Pitrufquén", code: "9114" },
      { id: "PUCON", name: "Pucón", code: "9115" },
      { id: "PUREN", name: "Purén", code: "9208" },
      { id: "RENAICO", name: "Renaico", code: "9209" },
      { id: "SAAVEDRA", name: "Saavedra", code: "9116" },
      { id: "TEMUCO", name: "Temuco", code: "9101" },
      { id: "TEODORO SCHMIDT", name: "Teodoro Schmidt", code: "9117" },
      { id: "TOLTEN", name: "Toltén", code: "9118" },
      { id: "TRAIGUEN", name: "Traiguén", code: "9210" },
      { id: "VICTORIA", name: "Victoria", code: "9211" },
      { id: "VILCUN", name: "Vilcún", code: "9119" },
      { id: "VILLARRICA", name: "Villarrica", code: "9120" },
    ],
  },
  {
    id: "LOS RIOS",
    name: "Los Ríos",
    romanNumber: "XIV",
    number: 14,
    code: "14",
    communes: [
      { id: "CORRAL", name: "Corral", code: "14102" },
      { id: "FUTRONO", name: "Futrono", code: "14202" },
      { id: "LA UNION", name: "La Unión", code: "14201" },
      { id: "LAGO RANCO", name: "Lago Ranco", code: "14203" },
      { id: "LANCO", name: "Lanco", code: "14103" },
      { id: "LOS LAGOS", name: "Los Lagos", code: "14104" },
      { id: "MARIQUINA", name: "Mariquina", code: "14106" },
      { id: "MAFIL", name: "Máfil", code: "14105" },
      { id: "PAILLACO", name: "Paillaco", code: "14107" },
      { id: "PANGUIPULLI", name: "Panguipulli", code: "14108" },
      { id: "RIO BUENO", name: "Río Bueno", code: "14204" },
      { id: "VALDIVIA", name: "Valdivia", code: "14101" },
    ],
  },
  {
    id: "LOS LAGOS",
    name: "Los Lagos",
    romanNumber: "X",
    number: 10,
    code: "10",
    communes: [
      { id: "ANCUD", name: "Ancud", code: "10202" },
      { id: "CALBUCO", name: "Calbuco", code: "10102" },
      { id: "CASTRO", name: "Castro", code: "10201" },
      { id: "CHAITEN", name: "Chaitén", code: "10401" },
      { id: "CHONCHI", name: "Chonchi", code: "10203" },
      { id: "COCHAMO", name: "Cochamó", code: "10103" },
      { id: "CURACO DE VELEZ", name: "Curaco de Vélez", code: "10204" },
      { id: "DALCAHUE", name: "Dalcahue", code: "10205" },
      { id: "FRESIA", name: "Fresia", code: "10104" },
      { id: "FRUTILLAR", name: "Frutillar", code: "10105" },
      { id: "FUTALEUFU", name: "Futaleufú", code: "10402" },
      { id: "HUALAIHUE", name: "Hualaihué", code: "10403" },
      { id: "LLANQUIHUE", name: "Llanquihue", code: "10107" },
      { id: "LOS MUERMOS", name: "Los Muermos", code: "10106" },
      { id: "MAULLIN", name: "Maullín", code: "10108" },
      { id: "OSORNO", name: "Osorno", code: "10301" },
      { id: "PALENA", name: "Palena", code: "10404" },
      { id: "PUERTO MONTT", name: "Puerto Montt", code: "10101" },
      { id: "PUERTO OCTAY", name: "Puerto Octay", code: "10302" },
      { id: "PUERTO VARAS", name: "Puerto Varas", code: "10109" },
      { id: "PUQUELDON", name: "Puqueldón", code: "10206" },
      { id: "PURRANQUE", name: "Purranque", code: "10303" },
      { id: "PUYEHUE", name: "Puyehue", code: "10304" },
      { id: "QUEILEN", name: "Queilén", code: "10207" },
      { id: "QUELLON", name: "Quellón", code: "10208" },
      { id: "QUEMCHI", name: "Quemchi", code: "10209" },
      { id: "QUINCHAO", name: "Quinchao", code: "10210" },
      { id: "RIO NEGRO", name: "Río Negro", code: "10305" },
      { id: "S.J. DE LA COSTA", name: "San Juan de la Costa", code: "10306" },
      { id: "SAN PABLO", name: "San Pablo", code: "10307" },
    ],
  },
  {
    id: "AYSEN",
    name: "Aysén del Gral. Carlos Ibáñez del Campo",
    romanNumber: "XI",
    number: 11,
    code: "11",
    communes: [
      { id: "AISEN", name: "Aysén", code: "11201" },
      { id: "CHILE CHICO", name: "Chile Chico", code: "11401" },
      { id: "CISNES", name: "Cisnes", code: "11202" },
      { id: "COCHRANE", name: "Cochrane", code: "11301" },
      { id: "COYHAIQUE", name: "Coyhaique", code: "11101" },
      { id: "GUAITECAS", name: "Guaitecas", code: "11203" },
      { id: "LAGO VERDE", name: "Lago Verde", code: "11102" },
      { id: "OHIGGINS", name: "O’Higgins", code: "11302" },
      { id: "RIO IBAÑEZ", name: "Río Ibáñez", code: "11402" },
      { id: "TORTEL", name: "Tortel", code: "11303" },
    ],
  },
  {
    id: "MAGALLANES",
    name: "Magallanes y de la Antártica Chilena",
    romanNumber: "XII",
    number: 12,
    code: "12",
    communes: [
      { id: "ANTARTICA", name: "Antártica", code:"12501"  },
      { id: "CABO DE HORNOS", name: "Cabo de Hornos (Ex Navarino)", code:"12201"  },
      { id: "LAGUNA BLANCA", name: "Laguna Blanca", code: "12102"  },
      { id: "NATALES", name: "Natales", code:"12401"  },
      { id: "PORVENIR", name: "Porvenir", code:"12301"  },
      { id: "PRIMAVERA", name: "Primavera", code:"12302"  },
      { id: "PUNTA ARENAS", name: "Punta Arenas", code: "12101" },
      { id: "RIO VERDE", name: "Río Verde", code: "12103" },
      { id: "SAN GREGORIO", name: "San Gregorio", code: "12104" },
      { id: "TIMAUKEL", name: "Timaukel", code: "12303" },
      { id: "T. DEL PAINE", name: "Torres del Paine", code: "12402" },
    ],
  },
];
