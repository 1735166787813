import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async newCreateStep(userData, petData, planData, servicesData, step) {
    try {

      let url;
      const jsonData = { userData, petData, planData, servicesData, step, sponsor: VUE_APP_SPONSOR };

      if (servicesData.type == 'create') {
        url = '/step/newCreate';
      } else if (servicesData.type == 'update') {
        url = '/step/newUpdate';
      }

      const carroResponse = await axios.post(
        `${VUE_APP_SHIELD_URL}${url}`,
        jsonData
      );

      return carroResponse;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error en el servicio Stepers",
      };
    }
  }

  static async newGetStepAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/newGetAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async createContactActiveCampain(userData, servicesData) {

    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/activeCampain/createContact`,
        {
          userData,
          servicesData,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el contacto",
      };
    }
  }

  static async updateContactActiveCampain(userData, planData, petData, servicesData, index) {

    try {

      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/activeCampain/updateContact`,
        {
          userData,
          planData,
          petData,
          servicesData,
          index,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async getContactActiveCampain(email) {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async getListContactActiveCampain() {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getListContact`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  //Lógica para redireccionar los planes cuando tiene un código de descuento
  static async getPlans(code, age, unit, cantPet) {
    if (unit === "M" && age < 10) {
      age = 0
    }
    else if (unit === "M" && age >= 10) {
      age = 0
    }
    else if (unit === "Y" && age < 10) {
      age = 0
    }
    else if (unit === "Y" && age >= 10) {
      // console.log("año y mayor  o igual q 10")
    }
    try {
      var url = `${VUE_APP_SHIELD_URL}/gallagher`; // URL inicial apuntando a backend gallagher
      var params = ""; // URL con parametro de código de descuento

      // if (code) { //If URL con código
      //   params = url + `?sponsor=${VUE_APP_SPONSOR}&code=${code}&age=${age}`;
      //   //priceCLP = "<strike>{{ formatPrice(planData.priceCLP) }}</strike>";
      // } else { // else URL sin código
      //   params = url + `?sponsor=${VUE_APP_SPONSOR}&category=perros-gatos3`;
      // }

      if(cantPet == 1){
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=perros-gatos1`;
      } else if(cantPet == 2){
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=perros-gatos2`;
      } else if(cantPet == 3){
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=perros-gatos3`;
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=perros-gatos4`;
      } 

      const shieldRes = await axios.get(
        //Entrega URL con parametros
        params
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {

    try {

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async createInsurance(person, pet, plan, stepId) {

    person.birthdate = person.dates
    const jsonData = { person, pet, stepId: stepId };
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher/createWithRunWayPaymentGroup`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }

  static async createInsuranceWithToku(person, pet, plan, stepId) {

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    const jsonData = { person, pet, planId: plan._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async getAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/getAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }
}
