export default [
  {
    id: 1,
    label: "6 meses",
    unit: "M",
    quantity: 6,
    code: "6M",
  },
  {
    id: 2,
    label: "7 meses",
    unit: "M",
    quantity: 7,
    code: "7M",
    
  },
  {
    id: 3,
    label: "8 meses",
    unit: "M",
    quantity: 8,
    code: "8M",
  },
  {
    id: 4,
    label: "8 meses",
    unit: "M",
    quantity: 8,
    code: "8M",
  },
  {
    id: 5,
    label: "9 meses",
    unit: "M",
    quantity: 9,
    code: "9M",
  },
  {
    id: 6,
    label: "10 meses",
    unit: "M",
    quantity: 10,
    code: "10M",
  },
  {
    id: 7,
    label: "11 meses",
    unit: "M",
    quantity: 11,
    code: "11M",
  },
  {
    id: 8,
    label: "1 año",
    unit: "Y",
    quantity: 1,
    code: "1Y",
  },
  {
    id: 9,
    label: "2 años",
    unit: "Y",
    quantity: 2,
    code: "2Y",
  },
  {
    id: 10,
    label: "3 años",
    unit: "Y",
    quantity: 3,
    code: "3Y",
  },
  {
    id: 11,
    label: "4 años",
    unit: "Y",
    quantity: 4,
    code: "4Y",
  },
  {
    id: 11,
    label: "4 años",
    unit: "Y",
    quantity: 4,
    code: "4Y",
    
  },
  {
    id: 12,
    label: "5 años",
    unit: "Y",
    quantity: 5,
    code: "5Y",
  },
  {
    id: 13,
    label: "6 años",
    unit: "Y",
    quantity: 6,
    code: "6Y",
  },
  {
    id: 14,
    label: "7 años",
    unit: "Y",
    quantity: 7,
    code: "7Y",
  },
  {
    id: 15,
    label: "8 años",
    unit: "Y",
    quantity: 8,
    code: "8Y",
  },
  {
    id: 15,
    label: "9 años",
    unit: "Y",
    quantity: 9,
    code: "9Y",
  },
];